import { createStore } from 'vuex'

import { coreui } from './coreui'
import { security } from './security'
import { config } from './config'

export default createStore({
  modules: {
    coreui,
    security,
    config
  }
})
