<template>
  <span v-html="getValueByIdentifier"/>
</template>

<script>

export default {
  name: 'app-config',
  props: {
    identifier: String
  },
  data () {
    return {
    }
  },
  async created () {
  },
  computed: {
    getValueByIdentifier () {
      return this.$store.getters['config/getValueByIdentifier'](this.identifier)
    }
  }
}
</script>
