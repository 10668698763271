<template>
  <CHeader position="sticky" class="mb-4">

    <CContainer fluid>

      <CHeaderToggler class="ps-1" @click="toggleShowSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>

      <CHeaderNav class="mr-auto d-none d-lg-block d-xl-block">
        <CNavItem class="px-3 toggle-profimodus" @click="toggleProfiModus">
          <CIcon name="cil-media-skip-backward" v-if="!profiModus"/>
          <CIcon name="cil-media-skip-forward" v-if="profiModus"/>
        </CNavItem>
      </CHeaderNav>

      <!-- Hauptnavigation -->
      <CHeaderNav class="d-none d-md-flex me-auto">
        <span v-for="navitem in navigation" v-bind:key="navitem.name">

          <CNavItem v-if="hasNoChildren(navitem)">
            <CNavLink :href="navitem.to">
              <CIcon :name="navitem.icon"/>
              <span class="d-md-down-none ms-2" v-if="!profiModus">{{navitem.name}}</span>
            </CNavLink>
          </CNavItem>

          <li v-else>
            <CDropdown variant="nav-item" class="custom-nav-dropdown">
              <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
                <CIcon :name="navitem.icon"/>
                <span class="d-md-down-none ms-2" v-if="!profiModus">{{navitem.name}}</span>
              </CDropdownToggle>

              <CDropdownMenu class="pt-0">
                <CDropdownHeader component="h6" class="py-2">
                  {{navitem.name}}
                </CDropdownHeader>

                <CDropdownItem v-for="navsubitem in navitem.items" v-bind:key="navsubitem.name">
                  <CNavLink :href="navsubitem.to">
                    {{navsubitem.name}}
                  </CNavLink>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </li>

        </span>
      </CHeaderNav>

      <CHeaderNav class="mr-0">
        <CNavItem class="px-3">
          <img :src="logo" style="padding: 5px; width: 200px; height: 50px; text-align: right" alt="wirfuerdich apotheke" class="" />
        </CNavItem>
      </CHeaderNav>

      <CDropdown variant="nav-item" class="custom-nav-dropdown">
        <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
          <span  class="mt-5 fs-4 fw-bold text-secondary" style="position: relative; top: 5px;"><Config identifier="name"></Config></span>
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
          <CDropdownHeader component="h6" class="py-2">
          </CDropdownHeader>
          <CDropdownItem>
            <CNavLink :href="'https://verwaltung-marktkauf.pflegekraft-apotheke.de'" target="_blank">
              <CIcon icon="cil-external-link" /> &nbsp; im Marktkauf
            </CNavLink>
          </CDropdownItem>
          <CDropdownItem>
            <CNavLink :href="'https://verwaltung-rathauspassage.pflegekraft-apotheke.de/'" target="_blank">
              <CIcon icon="cil-external-link" /> &nbsp; Rathauspassage
            </CNavLink>
          </CDropdownItem>
          <CDropdownItem>
            <CNavLink :href="'https://verwaltung-schlosspark.pflegekraft-apotheke.de/'" target="_blank">
              <CIcon icon="cil-external-link" /> &nbsp; Schlosspark
            </CNavLink>
          </CDropdownItem>
          <CDropdownItem>
            <CNavLink :href="'https://verwaltung-an-der-diana.pflegekraft-apotheke.de/'" target="_blank">
              <CIcon icon="cil-external-link" /> &nbsp; An der Diana
            </CNavLink>
          </CDropdownItem>
          <CDropdownItem>
            <CNavLink :href="'https://verwaltung-an-der-blickallee.pflegekraft-apotheke.de'" target="_blank">
              <CIcon icon="cil-external-link" /> &nbsp; Blickallee
            </CNavLink>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <CHeaderNav v-if="!darkMode" class="hidden mr-0">
        <CNavItem class="px-3 toggle-darkmode" v-on:click="toggleDarkMode()">
          <CIcon v-if="!darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </CNavItem>
      </CHeaderNav>

      <AppAccount/>

    </CContainer>
  </CHeader>
</template>

<script>
import Config from '@/components/Config'
import logo from '@/assets/brand/wirfuerdich-logo.svg'
import nav from '@/router/_nav.js'
import AppAccount from '@/coreui/components/default/AppAccount'
import _has from 'lodash/has'

export default {
  name: 'AppHeader',
  components: {
    Config,
    AppAccount
  },
  setup () {
    return {
      logo,
      nav
    }
  },
  computed: {
    darkMode () {
      return this.$store.getters['coreui/darkMode']
    },
    profiModus () {
      return this.$store.getters['coreui/profiModus']
    },
    navigation () {
      const navigation = nav.filter(n => {
        if (!n.header) {
          return null
        }
        if (_has(n, 'allowed')) {
          if (n.allowed.length > 0) {
            if (!this.$store.getters['security/userHasRole'](n.allowed)) {
              return null
            }
          }
        }
        return n
      })
      return navigation
    }
  },
  methods: {
    toggleDarkMode () {
      this.$store.commit('coreui/TOGGLE_DARKMODE')
    },
    toggleProfiModus () {
      this.$store.commit('coreui/TOGGLE_PROFIMODUS')
    },
    hasNoChildren (item) {
      let result = true
      if (item.items) {
        if (item.items.length > 0) {
          result = false
        }
      }
      return result
    },
    toggleShowSidebar () {
      this.$store.commit('coreui/TOGGLE_SHOW_SIDEBAR')
    }
  }
}
</script>

<style lang="scss">
  li.toggle-profimodus, li.toggle-darkmode {
    cursor: pointer;
  }
</style>
