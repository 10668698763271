import {
  cilBan,
  cilBoltCircle,
  cilCasino,
  cilEnvelopeClosed,
  cilGroup,
  cilHighlighter,
  cilHttps,
  cilLibraryAdd,
  cilLockLocked,
  cilLockUnlocked,
  cilLowVision,
  cilMagnifyingGlass,
  cilMenu,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMoon,
  cilPlus,
  cilSpeedometer,
  cilStorage,
  cilSun,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilUser,
  cilAddressBook,
  cilListRich,
  cilPencil,
  cilCommentBubble,
  cilHospital,
  cilHome,
  cilTruck,
  cilHamburgerMenu,
  cilStar,
  cilStarHalf,
  cilCart,
  cilPrint,
  cilCreditCard,
  cilInfo,
  cilExternalLink,
  cilArrowThickLeft,
  cilArrowThickRight
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilBan,
    cilPencil,
    cilPrint,
    cilBoltCircle,
    cilCasino,
    cilEnvelopeClosed,
    cilGroup,
    cilHighlighter,
    cilHttps,
    cilLibraryAdd,
    cilLockLocked,
    cilLockUnlocked,
    cilLowVision,
    cilMagnifyingGlass,
    cilMediaSkipBackward,
    cilMediaSkipForward,
    cilMoon,
    cilMenu,
    cilPlus,
    cilSpeedometer,
    cilStorage,
    cilSun,
    cilThumbDown,
    cilThumbUp,
    cilTrash,
    cilTruck,
    cilUser,
    cilAddressBook,
    cilListRich,
    cilCommentBubble,
    cilHospital,
    cilHome,
    cilHamburgerMenu,
    cilStar,
    cilStarHalf,
    cilCart,
    cilCreditCard,
    cilInfo,
    cilExternalLink,
    cilArrowThickLeft,
    cilArrowThickRight
  }
)
