<template>
  <AppSidebar />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <div class="content">
          <router-view />
        </div>
        <div class="container content-footer mt-5">
          <div class="row">
            <div class="col">
              <div class="text-left" >
                <img :src="logo" style="padding: 1rem; width: 200px; text-align: right" alt="wirfuerdich apotheke" class="" />
                <span style="position: relative; top: 10px;" class="mt-5 fs-4 fw-bold text-secondary"><Config identifier="name"></Config></span>
              </div>
            </div>
            <div class="col">
              <div class="text-end pt-4">
                <a class="btn btn-success" target="_blank" :href="frontendLink"><CIcon icon="cil-external-link" /> &nbsp; zur Pflege.Kraft Anwendung</a>
                &nbsp; <a class="btn btn-warning" target="_blank" href="https://form.asana.com/?k=Q4tNyGU7eIfPYW9Ns9kWXQ&d=44505656319659"><CIcon icon="cil-comment-bubble" /> &nbsp;Problem melden</a>
              </div>
            </div>
          </div>
        </div>
      </CContainer>
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import Config from '@/components/Config'
import AppHeader from '@/coreui//components/default/AppHeader.vue'
import AppSidebar from '@/coreui//components/default/AppSidebar.vue'
import logo from '@/assets/brand/wirfuerdich-logo.svg'
import { useStore } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
    Config
  },
  computed: {
    frontendLink () {
      return this.$store.getters['config/getValueByIdentifier']('frontend-url')
    }
  },
  setup () {
    const store = useStore()
    store.dispatch('config/load')
    return {
      logo
    }
  },
  async created () {

  }
}
</script>
